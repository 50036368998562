import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import TwoColDisplay from "../../components/two-col-display"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

const CyPage = () => {
  const query = useStaticQuery(graphql`
    {
      allFile(filter: { name: { eq: "profile-cy" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)

  const node = query.allFile.edges[0].node
  const image = node.childImageSharp.gatsbyImageData

  return (
    <Layout>
      <Seo title="Cy" />
      <div className="grid grid-cols-1 items-center justify-center w-full">
        <div className="pt-4 flex flex-grow justify-center items-center">
          <div className="max-w-4xl">
            <TwoColDisplay image={image}>
              <div className="text-center">
                <a href="https://instagram.com/cysterlingtattoo">
                  <h2 className="font-rubik text-xl no-underline text-black">
                    @cysterlingtattoo
                  </h2>
                </a>

                <div className="px-8 grid grid-cols-1 gap-4 py-4">
                  <div>
                    My name is Cy, I was born in England, I drink a lot of
                    coffee and smoke a lot of cigarettes. I’ve been tattooing
                    for 10 years.
                  </div>
                  <div>
                    In our spare time, my wife Sam and I Iove traveling to get
                    tattooed by people we admire & learning more about tattooing
                    and other cultures, which in turn influences the designs we
                    create for you.
                  </div>
                  <div>
                    I’ll tattoo any design in any style on you, but my favorite
                    stuff is pre 1900s European tattoo designs and classic
                    Americana motifs.
                  </div>
                  <div>Availability W,TH,F,S,S</div>
                  {/* <div>Contact Cy on Instagram</div> */}
                </div>
              </div>
            </TwoColDisplay>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => <Seo title="Cy" />

export default CyPage
